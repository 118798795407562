import React, { useState } from "react"
import Layout from "../components/layout"
import { Form, Col, Alert } from "react-bootstrap"
import TitleBlock from "../templates/blocks/TitleBlock"
import axios from "axios"
import SEO from "../components/seo"
import bgImg from "../images/contact/bandeau.jpg"
import logo from "../images/contact/logo_contact.png"
import roof from "../images/contact/top.png"
import GoogleMapReact from "google-map-react"
import GoogleMarker from "../components/GoogleMarker"

const Contact = () => {
  const [validated, setValidated] = useState(false)

  const [show, setShow] = useState(false)
  const [alertWindow, setAlertWindow] = useState(null)

  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [mail, setMail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [agency, setAgency] = useState("Montpellier")

  // const URL_CMS = 'http://localhost:8090/api/';
  // const URL_CMS = 'https://www-admin.afdtoccitanie.fr.iov01.ovea.com/api/';

  const handleSubmit = event => {
    // stop l'envoi du formulaire
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity()) {
      axios
        .get(`${process.env.GATSBY_CMS_API}authenticate_contact`)
        .then(res => {
          const token = res.data.id_token
          axios
            .post(
              `${process.env.GATSBY_CMS_API}send/contact`,
              {
                firstname,
                lastname,
                mail,
                phone,
                message,
                agency,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then(reponse => {
              if (reponse.status === 200) {
                setAlertWindow(
                  <Alert
                    dismissible
                    variant="primary"
                    onClose={() => setShow(false)}
                  >
                    <Alert.Heading>Formulaire envoyé</Alert.Heading>
                    <p>Nous vous répondrons incéssament sous peu !</p>
                  </Alert>
                )
              } else {
                setAlertWindow(
                  <Alert
                    dismissible
                    variant="danger"
                    onClose={() => setShow(false)}
                  >
                    <Alert.Heading>
                      Erreur d'envoi de votre formulaire
                    </Alert.Heading>
                    <p>Veuillez nous excuser de la gêne occasionner ...</p>
                  </Alert>
                )
              }
              // on rénitialise l'ensemble des champs
              setFirstname("")
              setLastname("")
              setMail("")
              setPhone("")
              setMessage("")

              // on affiche l'alerte
              setShow(true)
              setValidated(false)
            })
            .catch(error => {
              setShow(true)
              setAlertWindow(
                <Alert
                  dismissible
                  variant="danger"
                  onClose={() => setShow(false)}
                >
                  <Alert.Heading>
                    Erreur d'envoi de votre formulaire
                  </Alert.Heading>
                  <p>Veuillez nous excuser de la gêne occasionner ...</p>
                </Alert>
              )
            })
        })
        .catch(error => {
          setShow(true)
          setAlertWindow(
            <Alert dismissible variant="danger" onClose={() => setShow(false)}>
              <Alert.Heading>Erreur d'envoi de votre formulaire</Alert.Heading>
              <p>Veuillez nous excuser de la gêne occasionner ...</p>
            </Alert>
          )
        })
    } else {
      setValidated(true)
    }
  }
  return (
    <Layout
      ariane={[
        { title: "Accueil", url: "/" },
        { title: "Contact", url: "/contact" },
      ]}
    >
      <SEO titleSEO={"Contact"} />
      <TitleBlock
        data={{
          id: "contact",
          backgroundImage: bgImg,
          title: "Contact",
          balise: "H1",
          internal: true,
        }}
      />
      <div className="container contact">
        {show && alertWindow}
        <div className="row">
          <div className="col-md-6">
            <img src={logo} alt="logo AFDT" className="img-fluid" />
            <div className="roof">
              <img src={roof} alt="toit" className="top" />
              <div className="informations">
                <div className="informations-important">
                  <p>Montpellier :</p>
                  <p>Tel : 04.11.95.05.67</p>
                  <p>Fax: 04.34.48.01.39</p>
                  <p>agence.montpellier@afdtoccitanie.fr</p>
                </div>
                <p>363 rue du salaison</p>
                <p>34130 MAUGUIO</p>
              </div>
              <div className="informations">
                <div className="informations-important">
                  <p>Nîmes :</p>
                  <p>Tel : 04.66.64.73.19</p>
                  <p>agence.nimes@afdtoccitanie.fr</p>
                </div>
                <p>100 rue Octave Camplan</p>
                <p>30000 Nîmes</p>
              </div>
              <div className="informations">
                <div className="informations-important">
                  <p>Béziers :</p>
                  <p>Tel : 04.67.77.84.55</p>
                  <p>agence.beziers@afdtoccitanie.fr</p>
                </div>
                <p>14 rue Saint Victor</p>
                <p>34400 Villeneuve lès Béziers</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-auto">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="agency">
                  <Form.Label>Agence à contacter</Form.Label>
                  <Form.Control
                    as="select"
                    value={agency}
                    onChange={e => setAgency(e.target.value)}
                  >
                    <option>Montpellier</option>
                    <option>Nîmes</option>
                    <option>Béziers</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="firstname">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Prénom"
                    value={firstname}
                    onChange={e => setFirstname(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champs est requis
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="lastname">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nom"
                    value={lastname}
                    onChange={e => setLastname(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champs est requis
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="mail">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Adresse Mail"
                    value={mail}
                    onChange={e => setMail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champs est requis
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="phone">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Téléphone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champs est requis
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="message">
                  <Form.Control
                    as="textarea"
                    rows="8"
                    placeholder="Votre message..."
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <button type="submit" className="btn-green">
                Envoyer
              </button>
            </Form>
          </div>
        </div>
      </div>
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyBCJIQjdeYMsExOxK0836UvSEUSLI3xykg" }}
            defaultCenter={{ lat: 43.590503252739815, lng: 3.9410040405973414 }}
            defaultZoom={8}
        >
          <GoogleMarker
              lat={43.590503252739815}
              lng={3.9410040405973414}
              text="AU FIL DES TOITS, MONTPELLIER"
          />
          <GoogleMarker
              lat={43.85168892246099}
              lng={4.404746998274629}
              text="AU FIL DES TOITS, NÎMES"
          />
          <GoogleMarker
              lat={43.33380616696804}
              lng={3.280134286053754}
              text="AU FIL DES TOITS, BEZIERS"
          />
        </GoogleMapReact>
      </div>
    </Layout>
  )
}

export default Contact
