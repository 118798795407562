import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

function GoogleMarker({ text }) {
  return (
    <div className="d-flex aligns-item-center">
      <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" color="#E94335" />
      <span className="ml-2">{text}</span>
    </div>
  )
}

export default GoogleMarker
